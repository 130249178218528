import React, { useEffect } from 'react';
import { setModalLoaded } from '../helpers/helpers';

const FrogPrince = (props) => {

  const { showWhen } = props;
	
	useEffect(() => {
		setModalLoaded(showWhen)
  }, [showWhen]);

	return (
		<a-scene stats environment="preset: forest; lighting: point; lightPosition: -5 10 0;">
      <a-assets>
        <a-asset-item id="navmesh" src="FrogPrince/Castle-navmesh.glb"></a-asset-item>
        <a-asset-item id="castle" src="FrogPrince/Castle.glb"></a-asset-item>
        <a-asset-item id="gem" src="FrogPrince/rupee.glb"></a-asset-item>
      </a-assets>

      <a-entity id="rig"
                movement-controls="constrainToNavMesh: true;
                                   controls: gamepad, trackpad, keyboard;"
                position="-7 0 1">
        <a-entity camera
                  position="0 1.6 0"
                  look-controls="pointerLockEnabled: false">
          <a-cursor></a-cursor>
        </a-entity>
      </a-entity>

      <a-gltf-model id="wall-gem"
                checkpoint="offset: 0 -0.8 0;"
                position="-9 7 -6"
                src="#gem"
                scale="0.05 0.05 0.05"
                glow="color: #33FF66; intensity: 0.5"
                levitate>
        <a-light not-mobile type="point" intensity="0.5" color="#33FF66" distance="2" position="0.5 -0.25 0"></a-light>
      </a-gltf-model>
      <a-gltf-model id="tower-gem"
                checkpoint="offset: 0 -0.8 0;"
                position="0 10.2 -3.5"
                src="#gem"
                scale="0.05 0.05 0.05"
                glow="color: #33FF66; intensity: 0.5"
                levitate>
        <a-light not-mobile type="point" intensity="0.5" color="#33FF66" distance="2" position="0.5 -0.25 0"></a-light>
      </a-gltf-model>

      <a-gltf-model nav-mesh
                normal-material
                visible="false"
                src="#navmesh"></a-gltf-model>

      <a-gltf-model id="model" position="0 0 0"
                scale="3 3 3"
                src="#castle">
      </a-gltf-model>
    </a-scene>
	)
}

export default FrogPrince;