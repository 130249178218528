import React, { useEffect } from 'react';
import { setModalLoaded } from '../helpers/helpers';

const WizardOfOunces = (props) => {

	const { showWhen } = props;
	
	useEffect(() => {
		setModalLoaded(showWhen)
    }, [showWhen]);

    return (
      <a-scene loading-screen="enabled: false">
        <a-assets>
            <img id="floor" src="WOO/floor-1024.jpg" alt="floor" />
            <img id="firefox" src="WOO/firefox-quantum-1024.png" alt="firefox logo" />
            <a-asset-item id="wooScene" src="WOO/room.gltf"></a-asset-item>
            <img id="insideHouse" crossOrigin="anonymous" src="Rumpelstiltskin/insidehouse.png" alt="" />
        </a-assets>

            <a-sky src="#insideHouse"></a-sky>
            
            <a-plane src="#floor"
                     rotation="-90 0 0" scale="10000 10000 1" repeat="1000 1000"
                     shadow="receive: true">
            </a-plane>
            
            <a-entity position="0 0 5">
                <a-camera></a-camera>
            </a-entity>
            
            <a-gltf-model id="model" src="#wooScene" shadow="receive: true">
            </a-gltf-model>
            
            <a-image src="#firefox" scale="2 2 1" position="-2 3 -2.49">
            </a-image>
            
            <a-box color="#E0E0E0" scale="5 0.1 1" position="0 4 0"
                   shadow="cast: true">
            </a-box>
            
            <a-box color="#FFEB3B" scale="0.5 0.5 0.5" position="-1 1 0"
                   shadow="cast: true" shader="flat">
                <a-animation attribute="rotation"
                             to="0 360 0"
                             repeat="indefinite"
                             dur="5000"
                             easing="linear">
                </a-animation>
            </a-box>
            
            <a-box id="orange-box"
                   color="#FF5722" scale="0.5 0.5 0.5" position="1 1 0"
                   shadow="cast: true">
                <a-animation attribute="rotation"
                             to="0 360 0"
                             repeat="indefinite"
                             dur="5000"
                             easing="linear">
                </a-animation>
                <a-animation attribute="position"
                             to="1 1 -2"
                             repeat="indefinite"
                             direction="alternate"
                             dur="5000">
                </a-animation>
            </a-box>
            
            <a-entity light="type: ambient;
                             intensity: 0.2">
            </a-entity>
            
            <a-entity light="type: point;
                             intensity: 0.8;
                             distance: 10;
                             castShadow: true"
                      position="0 2 1.5">
                <a-animation attribute="light.intensity"
                             from="0.8"
                             to="0"
                             repeat="indefinite"
                             direction="alternate"
                             dur="5000">
                </a-animation>
            </a-entity>
            
            <a-entity light="type: spot;
                             target: #orange-box;
                             color: #C40070;
                             angle: 30;
                             penumbra: 0.1;
                             castShadow: true"
                      position="0 3 1">
            </a-entity>
      </a-scene>
  )
}

export default WizardOfOunces;
