import React, { useEffect } from 'react';
import { setModalLoaded } from '../helpers/helpers';

const Fox = (props) => {
	const { showWhen } = props;
	
	useEffect(() => {
		setModalLoaded(showWhen)
	}, [showWhen]);

	return (
		<div>
			<a-scene loading-screen="enabled: false">
				<a-assets>
					<a-asset-item id="castle" src="FrogPrince/Castle.glb"></a-asset-item>
					<img id="360-panorama" src="Hare/HareWorld.jpg" alt="" />
				</a-assets>
				<a-sky src="#360-panorama"></a-sky>
			
				<a-entity position="-1 0 10">
						<a-camera></a-camera>
				</a-entity>

				<a-gltf-model id="model" position="0 0 0"
					scale="3 3 3"
					src="#castle"></a-gltf-model>
			</a-scene>
		</div>
		
	)
}

export default Fox;