import React from 'react';

const CloseButton = ({toggleModal}) => {
	return (
		<div className="close-wrapper">
				<a href="#"  className="close-button" onClick={() => {
					console.log('toggled')
					toggleModal()
				}}>
				<div className="in">
					<div className="close-button-block"></div>
					<div className="close-button-block"></div>
				</div>
				<div className="out">
					<div className="close-button-block"></div>
					<div className="close-button-block"></div>
				</div>
			</a>
		</div>
	)
}

export default CloseButton;