import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { ModalContext } from "./modalContext";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    // backgroundColor: theme.palette.background.paper,
    // boxShadow: theme.shadows[5],
    // borderRadius: '16px',
  }
}));

const FTModal = (props) => {
  const classes = useStyles();
  let { toggle, isShowing, type } = React.useContext(ModalContext);
  const { content} = props

	let newComp;
	if(content) {
		newComp = React.cloneElement(content, {onClick: toggle})
  }
  
  const style = (type === 'BioCard')
    ? {top: '50%', left: '50%', transform: `translate(-50%, -50%)`}
    : {top: '16.7%', left: '30.0%', transform: `translate(-16.7%, -16.7%)`}

  return (
    <div>
      <Modal
        open={isShowing}
        onClose={toggle}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={style} className={classes.paper}>
          {newComp}
        </div>
        
      </Modal>
    </div>
  );
}

export default FTModal;
