import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile'
import BioCard from '../BioCard/BioCard';
import './ArtistCards.css';
import CloseButton from '../CloseButton/CloseButton';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
    top: '10%',
    position: 'absolute',
    left: '8%',
    width: '50vw',
    height: '70vh',
    borderRadius: '16px',  
  },
  gridList: {
    width: '100%',
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
}));

interface ArtistCardsProps {
  cardInfo: any
  onClick?: () => void,
  numOfElements: number
}

export const ArtistCards = React.memo(({ cardInfo, onClick, numOfElements }: ArtistCardsProps) => {
	const classes = useStyles();
  return (
    <div className={`ArtistCards ${classes.root}`}>
      <CloseButton toggleModal={onClick} />
      <GridList className={classes.gridList} cols={numOfElements}>
        {cardInfo.map((item: any, key: any) => {
          return (
            <GridListTile key={key}>
              <BioCard cardInfo={item} displayClose={false} />
            </GridListTile>
          )
        })
      }
      </GridList>
    </div>
  );
});
export default ArtistCards;