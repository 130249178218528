import React from 'react';
import ReactDOM from 'react-dom';
import {HashRouter as Router, Route} from 'react-router-dom';
import './index.css';
// import App from './App';
import reportWebVitals from './reportWebVitals';
import WizardOfOunces from './worlds/WizardOfOunces';
import TNSLP from './worlds/TNSLP';
import GoldieLocks from './worlds/GoldieLocks';
import Pinocchio from './worlds/Pinocchio';
import PussInBoots from './worlds/PussInBoots';
import NicholasKringle from './worlds/NicholasKringle';
import Hare from './worlds/Hare';
import FairyTellWorld from './worlds/FairyTellWorld';
import Rumpelstiltskin from './worlds/Rumpelstiltskin';
import FrogPrince from './worlds/FrogPrince';
import Fox from './worlds/Fox';
import Wellingtons from './worlds/Wellingtons';
import Guardians from './worlds/Guardians';
import Rapunzel from './worlds/Rapunzel';
import FTVR from './FTVR';

import { ModalProvider } from "./helpers/Modal/modalContext";

ReactDOM.render(
    <Router>
      <div>
        <ModalProvider>
            <Route exact path="/">
              <FTVR World={FairyTellWorld} homePage={true} />
            </Route>
            <Route path="/ftWorldScene">
              <FTVR World={FairyTellWorld} />
            </Route>
            <Route path="/wizardofounces">
              <FTVR World={WizardOfOunces} />
            </Route>
            <Route path="/rumpelstiltskin">
              <FTVR World={Rumpelstiltskin} />
            </Route>
            <Route path="/notsolittlepigs">
              <FTVR World={TNSLP} />
            </Route>
            <Route path="/goldielocks">
              <FTVR  World={GoldieLocks} />
            </Route>
            <Route path="/pinocchio">
              <FTVR World={Pinocchio} />
            </Route>
            <Route path="/frogprince">
              <FTVR World={FrogPrince} />
            </Route>
            <Route path="/pussinboots">
              <FTVR  World={PussInBoots} />
            </Route>
            <Route path="/nicholaskringle">
              <FTVR World={NicholasKringle} />
            </Route>
            <Route path="/hare">
              <FTVR World={Hare} fromPortal={true} />
            </Route>
            <Route path="/rapunzel">
              <FTVR World={Rapunzel} />
            </Route>
            <Route path="/fox">
              <FTVR World={Fox} />
            </Route>
            <Route path="/wellingtons">
              <FTVR World={Wellingtons} />
            </Route>
            <Route path="/guardians">
              <FTVR World={Guardians} />
            </Route>
            
        </ModalProvider> 
      </div>
    </Router>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
