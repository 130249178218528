import React from 'react';
// import { parseIdValue } from '../helpers';
import './DetailsSection.css';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

export default function DetailsSection(props) {
	const {onClick, storyTitle, storyDetails} = props;

	console.log('st: ', storyDetails)
	const isLive = storyDetails[4];
	const currentTitle = isLive ? storyTitle : 'Coming Soon';
	const buttonClick = isLive ? onClick : () => {};

	const createSlider = () => {
		return storyDetails[1].map(val => (
			<div>
					<img src={val} alt='Twisted Fairy Tell Character'/>
			</div>
		))
	}
	return (
		<details>
			<summary>
				<span className="summary-title">{storyTitle}</span>
				<div className="summary-chevron-up">
					<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>
				</div>
			</summary>

			<figure className="summary-content">
				<div className="profile-image">
					<Carousel
						showStatus={false}
						showThumbs={false}>
                {createSlider()}
            </Carousel>
				</div>
				<figcaption>
					<h3>{storyDetails[2]}</h3>
					<p>{storyDetails[0]}</p>
					<div className="icons">
						<a onClick={buttonClick} className="btn-flip" data-back={currentTitle} data-front='Enter The World'></a>
					</div>
				</figcaption>
			</figure>
			
			<div className="summary-chevron-down">
				<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-up"><polyline points="18 15 12 9 6 15"></polyline></svg>
			</div>
		</details>
	)
}
