import React, { useEffect } from 'react';
import { setModalLoaded } from '../helpers/helpers';

const NicholasKringle = (props) => {
	const { showWhen } = props;
	
	useEffect(() => {
		setModalLoaded(showWhen)
	}, [showWhen]);

	return (
		<a-scene loading-screen="enabled: false">
			<a-assets>
				<a-asset-item id="castle" src="NicholasKringle/FTKringle.glb"></a-asset-item>
				<img id="360-panorama" src="NicholasKringle/KringleWorld.jpg" alt="" />
			</a-assets>
    	{/* <a-sky src="#360-panorama"></a-sky> */}
		
			<a-entity light="type: hemisphere; color: #33C; groundColor: #3C3; intensity: 2"></a-entity>
			<a-entity position="-4.5 2.0 1" rotation="0 -45 0">
			<a-camera position="10 1.6 10">
				<a-entity cursor=""
						  raycaster="objects: .clickable"
						  geometry="primitive:ring;radiusInner:0.01;radiusOuter:0.02"
                          
                          material="shader:flat;color:blue"
                          animation__mouseenter="property:scale;to:3 3 3;startEvents:mouseenter;dir:reverse;dur:2000;loop:1">
				</a-entity>
			</a-camera>
		</a-entity>

			<a-gltf-model id="model" position="0 0 0"
					src="#castle"></a-gltf-model>
    </a-scene>
	)
}

export default NicholasKringle;