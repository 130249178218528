import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import PaletteOutlinedIcon from '@material-ui/icons/PaletteOutlined';
import BookOutlinedIcon from '@material-ui/icons/BookmarkBorderOutlined';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import InstagramIcon from '@material-ui/icons/Instagram';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import SearchIcon from '@material-ui/icons/Search';
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import BioCard from './BioCard/BioCard';
import ArtistCards from './ArtistCards/ArtistCards';
import AboutUs from './AboutUs/AboutUs';
import fairyTellsTeam from './BioCard/fairyTellsTeam';
import { ModalContext } from "./Modal/modalContext";

const drawerWidth = 240;
const AMAZON = 'https://www.amazon.com/Twisted-Fairy-Tells-Untold-Truths/dp/1665520833';
const INSTAGRAM = 'http://www.instagram.com/twistedfairytells';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    "& .MuiFormControl-root.MuiFormControl-marginNormal": {
      marginTop: 0,
    },
  },
  inputLabelRoot: {
    "& .MuiFormControl-root.MuiFormControl-marginNormal": {
      marginTop: 0,
    },
    color: "#ffffff",
    "&.focused": {
      color: "#ffffff"
    }
  },
  inputRoot: {
    color: "#fff",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
      padding: 0
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "white"
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "white"
    }
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },

  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    background: '#202124',
    color: 'white',
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
    background: '#202124',
    color: 'white',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const Navigation = ({setContent, setFocus, markers, onClickMarker}) => {
  const classes = useStyles();
	// const theme = useTheme();
	const [open, setOpen] = useState(false);
	let { toggle, setType } = React.useContext(ModalContext);
  const handleDrawerClose = () => {
    setOpen(!open);
  };

  // const [isShowing, setIsShowing] = useState(false);

  // function toggle() {  
  //   setIsShowing(!isShowing);
  //   console.log('toggled', isShowing)
  // }

	const toggleAndSetModal = (component=BioCard, type='ArtistCard') => {
    setContent(component);
    setType(type);
		toggle();
	}
	const onItemSelected = (e) => {
		const selectedItem = markers.filter(item => {
			return item.city === e.target.outerText;
		})
		if (selectedItem[0]) {
			setFocus(selectedItem[0].coordinates);
			onClickMarker(selectedItem[0],{uuid: 'testuuid'}, e.nativeEvent);
		}
	}

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {open ? <ChevronLeftIcon  style={{color: 'white'}} /> : <MenuIcon  style={{color: 'white'}} />}
          </IconButton>
        </div>
        <Divider style={{ background: 'white' }} />
        <List>
          {[
						{name: 'Search Story', icon:  SearchIcon, component: Autocomplete},
            {name: 'About', icon:  BookOutlinedIcon, component: <AboutUs />, type: 'AboutUs'},
						{name: 'Author', icon:  CreateOutlinedIcon, component: <ArtistCards
						cardInfo={fairyTellsTeam.slice(0,1)} numOfElements={1} />, type: 'ArtistCard'},
						{name: 'Artists', icon:  PaletteOutlinedIcon, component: <ArtistCards cardInfo={fairyTellsTeam.slice(1)} numOfElements={1} />, type: 'ArtistCard'}
					].map(item => {
						return (
            <ListItem button key={item.name}>
              <ListItemIcon onClick={() => {
								item.component === Autocomplete
									? handleDrawerClose()
									:	toggleAndSetModal(item.component, item.type)
								
							}}><item.icon style={{color: 'white'}}/></ListItemIcon>
							{item.component === Autocomplete 
								? <item.component
									id="combo-box-demo"
									options={markers}
                  classes={classes}
									getOptionLabel={(option) => option.city}
									style={{ width: 300 }}
									onChange={(value) => onItemSelected(value)}
									renderInput={(params) => (
										<TextField
                      {...params}
                      style={{color: '#fff'}}
                      label="Select a Story"
                      margin='normal' 
                      InputLabelProps={{ classes: {root: classes.inputLabelRoot}}}
  
                    />
									)}
								/>
								: <ListItemText primary={item.name} onClick={() => toggleAndSetModal(item.component, item.type)} />
							}
            </ListItem>
					)
								})}
        </List>
        <Divider style={{ background: 'white' }} />
        <List>
					{[
						{name: 'Store', icon: ShoppingCartOutlinedIcon, link: AMAZON },
						{name: 'Social Media', icon: InstagramIcon, link: INSTAGRAM },
					].map(item => {
            return (
							<ListItem button component="a" href={item.link} target="_blank" key={item.name}>
								<ListItemIcon><item.icon  style={{color: 'white'}}/></ListItemIcon>
								<ListItemText primary={item.name} />
							</ListItem>
						)
					})}
        </List>
      </Drawer>

    </div>
  );
}

export default Navigation;