import { useState } from 'react';

const useModal = () => {
  const [isShowing, setIsShowing] = useState(false);
  const [type, setType] = useState('');

  function toggle() {
    setIsShowing(!isShowing);
  }


  return {
    isShowing,
    toggle,
    setType,
    type
  }
};

export default useModal;
