import React from 'react';
import Particles from 'react-particles-js';
import './Loader.css';

const Loader = () => {

	return (
		<div id="loader-element"  className="loader-container">
			
			{/* <div className='container'>
				<div className='loader'>
					<div className='loader--dot'><span className="shadow"></span></div>
					<div className='loader--dot'><span className="shadow"></span></div>
					<div className='loader--dot'><span className="shadow"></span></div>
					<div className='loader--dot'><span className="shadow"></span></div>
					<div className='loader--dot'><span className="shadow"></span></div>
					<div className='loader--dot'><span className="shadow"></span></div>
					<div className='loader--text'><span className="shadow"></span></div>
				</div>
			</div> */}
			<Particles
				params={{ 
          particles: { 
            number: { 
              value: 250, 
              density: { 
                enable: true, 
                value_area: 2000, 
              } 
            }, 
					}, 
					interactivity: {
	        events: {
	            onhover: {
	                enable: true,
	                mode: "repulse"
	            }
	        }
	    	}
        }} 
			/>
			<div className="fadeIn" >
				<img style={{position: 'absolute',
					left: '50%',
  				top: '60%',
  				transform: 'translate(-50%,-50%)',
					maxWidth: '100%',
					height: '125%',
					objectFit: 'contain'}} 
					src="twistedFairyTellsImg.jpg" alt='Twisted Fairy Tells Background'
				/>
			</div>	
		</div>
		
	)
}

export default Loader;