import React from "react";
import useModal from "./useModal";
import FTModal from "./FTModal";

let ModalContext;
let { Provider } = (ModalContext = React.createContext());

let ModalProvider = ({ children }) => {
  let { isShowing, toggle, setType, type } = useModal();
  return (
    <Provider value={{ isShowing, toggle, setType, type }}>
      <FTModal />
      {children}
    </Provider>
  );
};

export { ModalContext, ModalProvider };
