const fairyTellsTeam = [
	{
		bioImagePath: 'williammoore.png',
		bioName: 'William Moore',
		bioTitle: 'Author/Webmaster',
		bio: 'Writing\'s a tool to take past experiences and create a world everyone can feel on a deeper subconscious level',
		socialMedia: [
			'jwoodsenterprise',
			'williammooretheauthor',
			'mojaviejames'
		]

	},
	{
		bioImagePath: 'william.jpg',
		bioName: 'William Eugene',
		bioTitle: 'Creator of Worlds',
		bio: 'Every aspect of this world was the realization of every outside force directly impacting what we perceive to be real.',
		socialMedia: [
			'W.Eugene.RBZZ',
			'weugene1150',
			'weugene1150'
			
		]
	},
	// {
	// 	bioImagePath: 'carlos.jpg',
	// 	bioName: 'Carlos Lopez',
	// 	bioTitle: 'Artist',
	// 	bio: 'Top notch artist that turns ideas into a tangible masterpiece',
	// 	socialMedia: [
	// 		'W.Eugene.RBZZ',
	// 		'ceelo_fwd',
	// 		'weugene1150'
	// 	]
	// },
	// {
	// 	'edgarparedes': {
	// 		bioImagePath: 'Artist-512.png',
	// 		bioName: 'Edgar Paredes',
	// 		bioTitle: 'Artist',
	// 		bio: 'Top notch artist that turns ideas into a tangible masterpiece'
	// 	}	
	// }
];

export default fairyTellsTeam;