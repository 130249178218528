import React from 'react';
import './BioCard.css';
import CloseButton from '../CloseButton/CloseButton';
import '@fortawesome/fontawesome-free';

interface BioCardProps {
  cardInfo: any
  displayClose?: boolean
  onClick?: () => void
} 
const BioCard = ({cardInfo, displayClose = true, onClick}: BioCardProps) => {
  const { bioImagePath, bioName, bioTitle, bio, socialMedia } = cardInfo
  return (
      <div className='property-card'>
        <aside className='profile-card'>
          <header>
              <img src={bioImagePath} className='hoverZoomLink' alt='profile avatar' />
          
            <h1>{bioName}</h1>
            <h2>{bioTitle}</h2>
          </header>
          {displayClose && <CloseButton toggleModal={onClick}/>}
          <div className='profile-bio'>
            <p>{bio}</p>
          </div>
          <ul className='smWrapper'> 
            <li>
              <a target='_blank' href={`https://www.facebook.com/${socialMedia[0]}`} rel='noreferrer'>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span className='fa fa-facebook'></span>
              </a>
            </li>
            <li>
              <a target='_blank' href={`https://www.instagram.com/${socialMedia[1]}`} rel='noreferrer'>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span className='fa fa-instagram'></span>
              </a>
            </li>
            <li>
              <a target='_blank' href={`https://www.twitter.com/${socialMedia[2]}`} rel='noreferrer'>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span className='fa fa-twitter'></span>
              </a>
            </li>
          </ul>
        </aside>
    </div>
  );
}

export default BioCard;