import '../App.css';
import React, { useState, useCallback } from 'react'; 
import ReactGlobe from 'react-globe';
// import FTVR from '../FTVR';
import defaultMarkers from '../data/markers';
// import listOfWorlds from '../helpers/listOfWorlds';
import markerRenderer from '../helpers/markerRenderer';
import DetailsSection from '../helpers/DetailsSection/DetailsSection';
import { parseIdValue } from '../helpers/helpers';
import Navigation from '../helpers/Navigation';
import FTModal from '../helpers/Modal/FTModal';
import { useHistory } from 'react-router-dom';

function markerTooltipRenderer(marker) {
  return `${marker.city}`;
}


const options = {
  markerRenderer,
  markerTooltipRenderer: (marker) => {
    return `${parseIdValue(marker.id)}: ${marker.city}`;
  },
  cameraAutoRotateSpeed: .5,
  cameraRotateSpeed: 0.2,
      
};

const FairyTellWorld = () => {
  const [storyDetails, setStoryDetails] = useState([]);
  const [storyTitle, setStoryTitle] = useState("");
  const [vr, setVR] = useState(false);
  const [storyLocation, setStoryLocation] = useState("");
  const [event, setEvent] = useState(null);
  const [markers, setMarkers] = useState(defaultMarkers);
	const [focus, setFocus] = useState(null)
	const [content, setContent] = useState(null);
	let history = useHistory();
	
	const onClickALink = (route) => {
    history.push(route);
	}

  const onClickMarker = useCallback((marker, markerObject, event ) => { 
    setEvent({
      type: "CLICK",
      marker,
      markerObjectID: markerObject.uuid,
      pointerEventPosition: { x: event.clientX, y: event.clientY }
    });
    setStoryLocation(marker.id);
    setStoryTitle(markerTooltipRenderer(marker));
		setStoryDetails(marker.color);
  }, []);

	const setter = (value) => {
		setContent(value)
	}
  const handleClick = () => {
				onClickALink(storyDetails[3]);
	}

  const onDefocus = useCallback(previousFocus => {
    setEvent({
      type: "DEFOCUS",
      previousFocus
    });
    setStoryDetails([]);
	}, []);

  return (
		<div className="App">
			<Navigation setFocus={setFocus} markers={markers} onClickMarker={onClickMarker} setContent={setter}
			 />
			<div className="fairyTell">
				<ReactGlobe
					focus={focus}
					height="100vh"
					cameraRotateSpeed="3"
					markers={markers}
					options={options}
					width="100vw"
					onClickMarker={onClickMarker}
					onDefocus={onDefocus}
				/>
				{/* <div id="ft_vr-player" className={vr ? 'shown' : null}>
					{vr && 
						<div id='ftApp' className='ftApp'>
							<div className='ftApp_AppContainer'>
								<FTVR world={listOfWorlds[storyLocation]} onClick={onClick}/>	
							</div>
						</div> 
					}
				</div> */}
				<FTModal
            content={content}
          />
				{storyDetails.length > 0 && (
					<div className="detailsContainer">
						<DetailsSection storyLocation={storyLocation} onClick={handleClick} storyTitle={storyTitle} storyDetails={storyDetails} />
					</div>
				)}
			</div>
		</div>
  );
}

export default FairyTellWorld;
