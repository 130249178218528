import React, { useState, useEffect, forwardRef } from 'react';
import Loader from './helpers/Loader/Loader';
import { CSSTransition } from 'react-transition-group';
import ExitButton from './helpers/ExitButton/ExitButton';

interface FTVRProps {
  fromPortal: boolean
  homePage?: boolean
  World: any
}
 const FTVR = forwardRef(({fromPortal=false, homePage, World}: FTVRProps, loaderRef) => {
  const [isLoading, setLoading] = useState(true);

  // const hide = () => {
  //   const sceneElement: HTMLElement | null = document.querySelector('.scene');
  //   if(sceneElement === null) {
  //     console.error('sceneElement does not exist');
  //   } else {
  //     sceneElement.classList.remove('show');
  //   }
  // }

  const show = () => {
   const sceneElement: HTMLElement | null = document.querySelector('.scene');
    if(sceneElement === null) {
      console.error('sceneElement does not exist');
    } else {
      sceneElement.classList.add('show');
    }  
  }

  const showWhen = (entity: HTMLElement, eventName = 'model-loaded') => {
    entity && entity.addEventListener(eventName, () => {
      console.log('showWhen: ', eventName, entity);
      show();
      setLoading(false);  
    })
  }

  // let buttonClick;
  // if (fromPortal) {
  //   buttonClick = () => {
  //     history.push({
  //       pathname: '/',
  //       state: {
  //         isLoading: true
  //       }
  //     });
  //     hide();
  //   }
  // } else {
  //   buttonClick = onClick;
  // }
  

  useEffect(() => {
    setTimeout(() => {
      if (homePage) {
        setLoading(false);
      }
    }, 1500);
  }, [homePage]);
  return (
    <>
    <main className='ft_vr_container'>
      <div>
        <div className="scene">
          <World showWhen={showWhen}/>
				    <ExitButton />
          
        </div>
			  <CSSTransition
          in={isLoading}
            timeout={300}
            classNames='fade'
            unmountOnExit
            onExited={() => show()}
        >
				  <Loader />
			  </CSSTransition>
        
      </div>
      
    </main>
    </>
    
  )
})

export default FTVR;