import React, { useEffect } from 'react';
import 'aframe-event-set-component';
import { setModalLoaded } from '../helpers/helpers';

const PussInBoots = (props) => {

  const { showWhen } = props;
	
	useEffect(() => {
		setModalLoaded(showWhen)
  }, [showWhen]);

	return (
			<a-scene loading-screen="enabled: false">
        <a-assets>
            <img id="floor" src="common/floor-1024.jpg" alt="floor" />
            <a-asset-item id="pussScene" src="LRRH/Wall_Packaged1.gltf"></a-asset-item>
            <a-asset-item id="castle" src="FrogPrince/Castle.glb"></a-asset-item>
        </a-assets>
        <a-entity position="0 2.2 4">
          <a-camera>
            <a-cursor position="0 0 -3"
            fuse="true"
            fuseTimeout="100"
                      raycaster="far: 20; interval: 1000; objects: .clickable"
                      geometry="primitive: sphere; radius: 0.07;"
                      material="color: black; shader: flat">
            </a-cursor>
          </a-camera>
        </a-entity>
        <a-box className="clickable" position="-1 0.5 -3" rotation="0 45 0" color="#4CC3D9"
           event-set__rescol="_event: click; _target: #plane; material.wireframe: false"
           event-set__tarclass="event: click; className: clickable"
           event-set__leave="_event: mouseleave; color: #4CC3D9"></a-box>

        <a-plane 
              id="plane" className="clickable" src="#floor" rotation="-90 0 0" scale="20 20 1" repeat="2 2"
              event-set__rescol="_event: click; material.wireframe: true">
        </a-plane>

        <a-gltf-model src="#pussScene"></a-gltf-model>
        <a-gltf-model id="model" position="0 0 0"
					scale="3 3 3"
					src="#castle"></a-gltf-model>
      </a-scene>
		)
}

export default PussInBoots;