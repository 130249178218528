import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import './AboutUs.css';
import CloseButton from '../CloseButton/CloseButton';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    overflow: 'auto',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    backgroundColor: theme.palette.background.paper,
    top: '10%',
    position: 'absolute',
    left: '8%',
    width: '50vw',
    height: '70vh',
    borderRadius: '16px',
    padding: '3em',
  },
  gridList: {
    flexWrap: 'nowrap',
    transform: 'translateZ(0)',
  },
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
}));

interface AboutUsProps {
  cardInfo: any
  onClick?: () => void,
  numOfElements: number
}

export const AboutUs = React.memo(({ onClick }: AboutUsProps) => {
	const classes = useStyles();
  return (
    <div className={`AboutUs ${classes.root}`}>
      <CloseButton toggleModal={onClick} />
      <div>
        <header>About Twisted Fairy Tells</header>
        <p>	Every story. Every tale has a history. A background that differs from the narrative that has been shared with the world. But every story has some basis in truth created from the mind of someone with a purpose—a motive. Many millennia ago, the Wellington family was blessed with the curse being the keeper of the stories. Blessed to know the truth and dissimilarly cursed to live in solitude to safe keep a history that the world was not ready to acknowledge indeed existed beyond their imaginations. History is a butterfly effect of events intertwined between the lives of many prominent figures and people. 
      </p>
      <p>
        What you know is not necessarily the truth? And what you are not aware of does not prove that it is not so. Behind our reality's veil is a world of mystery and lessons that a higher being hopes to learn from us as much as they attempt to teach us. Where old Saint Nick may not be as jolly, and Pinnochio just wants to be as Real as any other boy. The Yellow Brick Road is not only laid with gold and hopes and dreams, and the Easter Bunny only wanted to enjoy the peaceful serenity of his family. What if fairy tales and children's stories were equally part happy endings and part tastes of hell. If the original stories were leaked for profit, but the Wellingtons feel now is the time to expose the untold truths behind the Twisted Fairy Tells.</p>
      </div>
    </div>
  );
});

export default AboutUs;