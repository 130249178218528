import React, {useEffect} from 'react';
import { setModalLoaded } from '../helpers/helpers';

const TNSLP = (props) => {

	const { showWhen } = props;
	
	useEffect(() => {
		setModalLoaded(showWhen)
	}, [showWhen]);
	
	return (
		<a-scene loading-screen="enabled: false">
       <a-assets>
        <img id="insideHouse" crossOrigin="anonymous" src="Rumpelstiltskin/insidehouse.png" alt="" />
        <a-asset-item id="tnslpScene" src="TNSLP/Test2.gltf"></a-asset-item>
        <a-asset-item id="test3-m" src="TNSLP/Test3.mtl"></a-asset-item>
        <a-asset-item id="test3-o" src="TNSLP/Test3.obj"></a-asset-item>
       </a-assets>
			<a-sky src="#insideHouse"></a-sky>
			
			<a-entity position="-1 0 10">
					<a-camera camera="" position="-48 1.6 8" rotation="-22 133.5 0" ></a-camera>
			</a-entity>
			<a-gltf-model id="model" src="#tnslpScene"></a-gltf-model>
				
			<a-entity  obj-model="obj: #test3-o; mtl: #test3-m"
								position="-10 0 10"
								scale="0.5 0.5 0.5"
								rotation="0 200 0">
					
			</a-entity>
    </a-scene>
	)
}

export default TNSLP;

