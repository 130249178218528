import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { setModalLoaded } from '../helpers/helpers';

const Rumpelstiltskin = (props) => {
	let history = useHistory();

	const { showWhen } = props;
	
	useEffect(() => {
		setModalLoaded(showWhen)
  }, [showWhen]);
	

	const onClickALink = (evt, route) => {
		evt.preventDefault();
    history.push(route);
	}
	
	return (
		<a-scene loading-screen="enabled: false">
		<a-assets>
				<img id="insideHouse" crossOrigin="anonymous" src="Rumpelstiltskin/insidehouse.png" alt="" />
				<img crossOrigin="anonymous" src="common/TexturesCom_CliffRock3_2x2_512_albedo_tif.png" alt="" />
				<img crossOrigin="anonymous" src="common/TexturesCom_CliffRock3_2x2_512_normal.png" alt="" />
				<img crossOrigin="anonymous" src="common/TexturesCom_CliffRock3_2x2_512_roughness.png" alt="" />
				<img id="insideHouse" crossOrigin="anonymous" src="Rumpelstiltskin/insidehouse.png" alt="" />
				{/* <a-asset-item id="rumpelScene" src="Rumpelstiltskin/field_test_packed.gltf"></a-asset-item> */}
				<a-asset-item id="rumpelScene" src="common/fairyForestWorldReal.gltf"></a-asset-item>
				<a-asset-item id="house" src="common/chunks_h.glb"></a-asset-item>

		</a-assets>
		<a-entity position="-4.5 2.0 1" rotation="0 -45 0">
			<a-camera >
				<a-entity cursor=""
						  raycaster="objects: .clickable"
						  geometry="primitive:ring;radiusInner:0.01;radiusOuter:0.02"
                          position="0 0 -1.8"
                          material="shader:flat;color:blue"
                          animation__mouseenter="property:scale;to:3 3 3;startEvents:mouseenter;dir:reverse;dur:2000;loop:1">
				</a-entity>
			</a-camera>
		</a-entity>
		<a-gltf-model id="model" src="#rumpelScene"></a-gltf-model>
		<a-gltf-model src="#house" position="-43 0 -9.8"></a-gltf-model>
		<a-link class="clickable"
						href="#"
						position="-0.7 2 4"
						image="#insideHouse"
						onClick={(evt) => onClickALink(evt, '/tnslpscene')}
						title="Chunk's Home"/>

	</a-scene>
	)
}

export default Rumpelstiltskin;