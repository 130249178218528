const markers = [
    {
      id: 'North Pole-NicholasKringle',
      city: 'Nicholas Kringle',
      color: [
        'Nicholas Kringle brings children Christmas cheer but their parents have a different perspective.',
        // 'NicholasKringle/NicholasKringle.JPG',
        ['NicholasKringle/SantaClaus_TFT.jpeg'],
        'North Pole via Patara, Turkey',
        '/nicholaskringle',
        false
    ],
			coordinates: [90.0000, 135.0000],
			value: 100,
    },
    
    {
      id: 'Sweden-GoldieLocks',
      city: "Goldie's Locks",
      color: [
        'Goldie has locked into a different type of crowd that has become more like home.',
        // 'GoldieLocks/GoldieLocks.JPG',
        ['twistedFairyTellsImg.jpg'],
        'Hälsingland Forest, Sweden',
        '/goldielocks',
        false
    ],
			coordinates: [61.512, 16.507],
			value: 100,
    },
    {
      id: 'Australia-WOO',
      city: 'The Wizard Of Ounces',
      color: [
        'Dorothy and her band of rebels create financial and authoritarian change at any costs.',
        // 'LRRH/NotSoLittlePigs.JPG',
        ['WOO/Dorothy_TFT.png', 'WOO/Lionman_TFT.jpeg', 'WOO/Scarecrow_TFT.png', 'WOO/Tinman_TFT.png'],
        'Great Victoria Desert, Australia',
        '/wizardofounces',
        false
    ],
			coordinates: [-25.8559, 129.4796],
			value: 100,
    },
    {
      id: 'France-TNSLP',
      city: '3 Not So Little Pigs',
      color: [
        'Just because you are an invited guest, does not always mean that you are welcome to eat \'with\' the hosts.',
        // 'LRRH/NotSoLittlePigs.JPG',
        ['twistedFairyTellsImg.jpg'],
        'Forest of Fontainebleau, France',
        '/notsolittlepigs',
        false
        
      ],
			coordinates: [48.4193, 2.6330],
			value: 100,
		},
		{
      id: 'France-PussInBoots',
      city: 'Puss In Boots',
      color: [
        'The face of the family business is not always the boss. Sometimes it is the person that you least expect.',
        // 'PussInBoots/PussInBoots.jpg',
        ['twistedFairyTellsImg.jpg'],
        'Lyon, France',
        '/pussinboots',
        false
      ],

			coordinates: [45.763420, 4.834277],
			value: 100,
		},
    {
      id: 'France-Pinnochio',
      city: 'Pinocchio',
      color: [
        'Maybe he cannot be a real boy, but he will find the feeling even at the cost of real boys.',
        // 'PINNOCHIO/pinocchio.JPG',
        ['Pinocchio/Pinocchio.TFT.jpeg'],
        'Paris, France',
        '/pinocchio',
        false
      ],
      coordinates: [48.8566, 2.3522],
      value: 100,
    },
    {
      id: 'Germany-FrogPrince',
      city: 'Frog Prince',
      color: [
        'The children may forgive the sins of their parents. but they do not have to forget. But wait for the opportunity for...',
        // 'FrogPrince/FrogPrince.JPG',
        ['twistedFairyTellsImg.jpg'],
        'Black Forest, Germany',
        '/frogprince',
        false

      ],
      coordinates: [46.6617, 15.4687],
      value: 100,
    },
    {
      id: 'France-Rumpelstiltskin',
      city: 'Rumpelstiltskin',
      color: [
        'Rumpelstiltskin may have sacrificed himself for his family but they does not mean he cannot have a little fun in the process.',
        // 'Rumpelstiltskin/Rumpelstiltskin.jpg',
        ['twistedFairyTellsImg.jpg'],
        'Strasbourg, France',
        '/rumpelstiltskin',
        false
      ],
      coordinates: [48.5734, 7.7521],
      value: 100,
    },
    {
      id: 'Germany-Hare',
      city: 'Hare The Easter Bunny',
      color: [
        'Hare just wanted to be left alone. Now you must face the wrath of a \'cute\' not so little bunny.',
        // 'Hare/HareEasterBunny.jpg',
        ['Hare/CuteEasterBunny_TFT.jpeg', 'Hare/MonsterEasterBunny_TFT.jpeg'],
        'Naumberg, Germany',
        '/hare',
        false
      ],
      coordinates: [51.1520, 11.8142],
      value: 100,
    },

    {
      id: 'Germany-Fox',
      city: 'The Fox',
      color: [
        'The Fox is not only sly but a manipulator of destiny at other\'s detriment. But what goes around must always return to its source.',
        // 'Hare/HareEasterBunny.jpg',
        ['twistedFairyTellsImg.jpg'],
        'Naumberg, Germany',
        '/fox',
        false
      ],
      coordinates: [51.1520, 11.8142],
      value: 100,
    },

    {
      id: 'Italy-Rapunzel',
      city: 'Rapunzel',
      color: [
        'No matter how much the world tries to take, your parent\'s love can always overcome damage and give you what you need in the end.',
        // 'Hare/HareEasterBunny.jpg',
        ['twistedFairyTellsImg.jpg'],
        'Naples, Italy',
        '/rapunzel',
        false
      ],
      coordinates: [40.8518, 14.2681],
      value: 100,
    },

    {
      id: 'Nevis Island-Wellingtons',
      city: 'The Wellingtons',
      color: [
        'The lineage chosen by the gods as the Keepers of the Tales',
        ['Wellington/CharlesWellington_TFT.jpeg'],
        'Nevis Island, Lesser Antilles',
        '/wellingtons',
        false
      ],
      coordinates: [17.1554, 62.5796],
      value: 100,
    },

    {
      id: 'Null Island-Guardians',
      city: 'The Guardians',
      color: [
        'Every story has to begin somewhere. The Creators of Tales.',
        // 'Hare/HareEasterBunny.jpg',
        ['twistedFairyTellsImg.jpg'],
        'Null Island',
        '/guardians',
        false
      ],
      coordinates: [0, 0],
      value: 100,
    },
  ];

  export default markers;